/**
 * Foursource page
 * https://foursource.com/case-studies
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";
import { Link } from "gatsby"

import { caseStudies } from "../content/pages/caseStudy";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Card from "../components/_partials/_card";

class caseStudy extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.caseStudies = caseStudies(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="case-study" whiteMenu={false}>
          <TextImgBackgroundFull
            info={this.caseStudies.topInfo}
          ></TextImgBackgroundFull>
          <div className="row ml-10 mr-10 mt-0 mb-100 d-flex justify-content-center">
            {this.caseStudies.cardsInfo.caseStudies.map((caseStudy, i) => (
              <>
                {caseStudy.url && (
                  <Link to={caseStudy.url}>
                    <Card
                      logoImg={caseStudy?.logoImg}
                      logoDescription={caseStudy.logoDescription}
                      description={caseStudy.description}
                      photo={caseStudy?.photo}
                      testimonyName={caseStudy.testimonyName}
                      title={caseStudy.title}
                      caseStudy= {caseStudy.caseStudy}
                      key={i}
                    ></Card>
                  </Link>
                )}
              </>
            ))}
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(caseStudy);
