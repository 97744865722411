import Utm from "../../components/_global/utm";

//images associates
import confi from "../../images/caseStudies/confi.svg";
import nexgenSportswear from "../../images/nexgen-sportswear/nexgenLogo.svg";
import seyfei from "../../images/caseStudies/seyfei.svg";
import buyer from "../../images/caseStudies/buyer.svg";
import ff from "../../images/caseStudies/ff.svg";
import yellowRiver from "../../images/caseStudies/yellow-river.svg";
import limton from "../../images/caseStudies/limton.svg";
import asbx from "../../images/caseStudies/asbx-logo.jpg";
import araujoirmaos from "../../images/caseStudies/araujoirmaos.svg";
import cuteDress from "../../images/cute-dress/logo.jpg";
import stuffLimited from "../../images/stuff-limited/stuff-limited-logo.jpg";
import camproSports from "../../images/campro-sports/campro-sports-logo.jpg"

import TopBackground from "../../images/pricing.jpg";

export const caseStudies = (t) => {
  return {
    topInfo: {
      title: {
        text: t("case-study.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("case-study.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("case-study.ctaText"),
        url:
          process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        extraClass: "bigWidth",
      },
      cta2: {
        text: t("meta.demoRequestText"),
        url: t("meta.demoDeskLink"),
        type: "infoColor",
        extraClass: "bigWidth ml-20"
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("case-study.imgAlt"),
        smallHeight: true,
      },
    },
    cardsInfo: {
      caseStudies: [
        {
          logoImg: confi,
          logoDescription: t("case-study.ispoLogo"),
          testimonyName: t("case-study.apparelItaly"),
          title: t("case-study.confiTestimony"),
          url: t("case-study.confiUrl"),
          caseStudy: true,
        },
        {
          logoImg: seyfei,
          logoDescription: t("case-study.bangladeshLogo"),
          testimonyName: t("case-study.apparelTurkey"),
          title: t("case-study.seyfeliTestimony"),
          url: t("case-study.seyfeliUrl"),
          caseStudy: true,
        },
        {
          logoImg: buyer,
          logoDescription: t("case-study.oekoLogo"),
          testimonyName: t("case-study.buyerItalyGermany"),
          title: t("case-study.buyerTestimony"),
          url: t("case-study.buyerUrl"),
          caseStudy: true,
        },
        {
          logoImg: ff,
          logoDescription: t("case-study.iafLogo"),
          testimonyName: t("case-study.apparelTurkey"),
          title: t("case-study.ffTestimony"),
          url: t("case-study.ffUrl"),
          caseStudy: true,
        },
        {
          logoImg: yellowRiver,
          logoDescription: t("case-study.wrapLogo"),
          testimonyName: t("case-study.apparelChina"),
          title: t("case-study.yrTestimony"),
          url: t("case-study.yrUrl"),
          caseStudy: true,
        },
        {
          logoImg: limton,
          logoDescription: t("case-study.dtbLogo"),
          testimonyName: t("case-study.apparelPakistan"),
          title: t("case-study.limtonTestimony"),
          url: t("case-study.limtonUrl"),
          caseStudy: true,
        },
        {
          logoImg: asbx,
          logoDescription: t("case-study.asbxLogo"),
          testimonyName: t("case-study.apparelPortugal"),
          title: t("case-study.asbxTestimony"),
          url: t("case-study.asbxUrl"),
          caseStudy: true,
        },
        {
          logoImg: araujoirmaos,
          logoDescription: t("case-study.araujoirmaosLogo"),
          testimonyName: t("case-study.apparelPortugal"),
          title: t("case-study.araujoirmaosTestimony"),
          url: t("case-study.araujoirmaosUrl"),
          caseStudy: true,
        },
        {
          logoImg: cuteDress,
          logoDescription: t("case-study.cuteDressLogo"),
          testimonyName: t("case-study.apparelBangladesh"),
          title: t("case-study.cuteDressTestimony"),
          url: t("case-study.cuteDressUrl"),
          caseStudy: true,
        },
        {
          logoImg: nexgenSportswear,
          logoDescription: t("case-study.nexgenSportswearLogo"),
          testimonyName: t("case-study.nexgenSportswear"),
          title: t("case-study.nexgenSportswearTestimony"),
          url: t("case-study.nexgenSportswearUrl"),
          caseStudy: true,
        },
        {
          logoImg: stuffLimited,
          logoDescription: t("case-study.stuffLimitedLogo"),
          testimonyName: t("case-study.apparelBangladesh"),
          title: t("case-study.stuffLimitedTestimony"),
          url: t("case-study.stuffLimitedUrl"),
          caseStudy: true,
        },
        {
          logoImg: camproSports,
          logoDescription: t("case-study.camproSportsLogo"),
          testimonyName: t("case-study.apparelPakistan"),
          title: t("case-study.camproSportsTestimony"),
          url: t("case-study.camproSportsUrl"),
          caseStudy: true,
        },
      ],
    },
  };
};
